import { Link, navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../fundamentals/button"
import SignInInput from "../../molecules/input-signin"

import AuthController from "../../../services/auth"
import useNotification from "../../../hooks/use-notification"
import { toast } from "react-hot-toast"
import Modal from "../../molecules/modal"
import { AccountContext } from "../../../context/account"

type FormValues = {
  email: string
  password: string
}

type LoginCardProps = {
  toResetPassword: () => void
}

const LoginCard: React.FC<LoginCardProps> = ({ toResetPassword }) => {
  const [isInvalidLogin, setIsInvalidLogin] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { register, handleSubmit, reset } = useForm<FormValues>();
  const { profile } = useContext(AccountContext)

  const onSubmit = async (values: FormValues) => {
    setIsLoading(true)
    AuthController.login(values)
      .then((res) => {
        setTimeout(() => {
          navigate("/")
        }, 2000)
        window.sessionStorage.setItem("hacker_you", res?.data?.result)
      })
      .catch((err) => {
        toast.error("Có lỗi xảy ra")
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      })
  }

  useEffect(() => {
    const token = window.sessionStorage.getItem("hacker_you")

    if (token && profile) {
      navigate('/')
      return;
    }

    window.sessionStorage.removeItem('hacker_you')
  }, [profile])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-center">
          <span className="inter-2xlarge-semibold mt-4 text-grey-90">
            Welcome back!
          </span>
          <span className="inter-base-regular text-grey-50 mt-2">
            It's great to see you 👋🏼
          </span>
          <span className="inter-base-regular text-grey-50 mb-xlarge">
            Log in to your account below
          </span>
          <SignInInput
            placeholder="Email..."
            {...register("email", { required: true })}
            autoComplete="email"
          />
          <SignInInput
            placeholder="Password..."
            type={"password"}
            {...register("password", { required: true })}
            autoComplete="current-password"
          />
          {isInvalidLogin && (
            <span className="text-rose-50 w-full mt-2 inter-small-regular">
              {isInvalidLogin}
            </span>
          )}
          <Button
            className="rounded-rounded mt-4 w-[320px] inter-base-regular"
            variant="primary"
            size="large"
            type="submit"
            loading={isLoading}
          >
            Continue
          </Button>
          <span
            className="inter-small-regular text-grey-50 mt-8 cursor-pointer"
            onClick={toResetPassword}
          >
            Reset password
          </span>
        </div>
      </form>
      <Modal open={openModal}>
        <Modal.Header
          handleClose={() => {
            setOpenModal(false)
          }}
        >
          <div className="font-semibold">Hello Our Customer</div>
        </Modal.Header>
        <Modal.Body className="p-6">
          <div>You had login succeed!</div>
          <div className="text-gray-400 italic">Please choose below.</div>
        </Modal.Body>
        <Modal.Footer className="grid gap-2 grid-cols-2">
          <Link to="/nail/dashboard">
            <a className="btn btn-medium rounded-rounded mt-4 inter-base-regular bg-gray-500 hover:bg-gray-300 ">
              <img src="/images/icons/nail-polish.png" className="w-6" />
              Nail Salon
            </a>
          </Link>

          <Link to="/retail/dashboard">
            <a className="btn btn-medium btn-primary rounded-rounded mt-4 inter-base-regular">
              <img src="/images/icons/market.png" className="w-6" />
              Retail
            </a>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LoginCard
